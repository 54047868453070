import React, { useMemo } from 'react';
import { HistoryServiceV3 } from '@feature-hub/history-service';
import { ServiceConfigurationV1 } from '@volkswagen-onehub/gfa-service-config-provider';

export type AppContextType = {
  historyService: HistoryServiceV3;
  contentApiConfig: ServiceConfigurationV1;
  isHeadless: boolean;
  fefaFeatureAppUrl?: string;
  oneFootnoteUrl?: string;
};

export const AppContext = React.createContext<AppContextType>({
  isHeadless: false,
  contentApiConfig: {},
  historyService: null,
  fefaFeatureAppUrl: '',
  oneFootnoteUrl: ''
} as unknown as AppContextType);

export interface AppContextProviderProps {
  historyService: HistoryServiceV3;
  contentApiConfig: ServiceConfigurationV1;
  isHeadless: boolean;
  fefaFeatureAppUrl?: string;
  oneFootnoteUrl?: string;
  children?: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = (props) => {
  const {
    children,
    historyService,
    fefaFeatureAppUrl,
    oneFootnoteUrl,
    isHeadless,
    contentApiConfig
  } = props;

  const value = useMemo(
    () => ({
      historyService,
      contentApiConfig,
      fefaFeatureAppUrl,
      oneFootnoteUrl,
      isHeadless
    }),
    []
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
