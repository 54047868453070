/* eslint-disable no-restricted-globals */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { ALERT } from './globals';
import OneLayerManager from './one-layer-manager';
import Shader from './shader';
import { AppContext } from '../context/app';

// interfaces
export interface IOneLayerCollection {
  layerManager: LayerManagerV27;
  zIndexAlert: number;
  zIndex?: number;
  featureAppId: string;
}

// component
const OneLayerCollection: React.FC<IOneLayerCollection> = (props) => {
  const { layerManager, zIndexAlert, zIndex, featureAppId } = props;
  const { historyService } = useContext(AppContext);

  const [layers, setLayers] = useState(layerManager.getLayers());

  const [scrollOffsetState, setScrollOffsetState] = useState({});

  useEffect(() => {
    const hasDeepLink =
      historyService.rootHistory?.location?.hash?.includes('#one-layer=') ||
      historyService.rootHistory?.location?.hash?.includes('#oneLayer=');
    // @ts-expect-error if id exists, it's not undefined
    if (historyService.rootHistory?.location?.state?.id === featureAppId && hasDeepLink) {
      historyService.rootHistory.back();
    }
  }, []);

  const onUpdate = useCallback(
    (event: string) => {
      if (!event || event === 'update') {
        const layers_ = layerManager.getLayers();
        setLayers(layers_);
      }
    },
    [layerManager]
  );

  const setOneLayerScrollOffset = (id: string, n: number): void => {
    setScrollOffsetState((state) => ({ ...state, [id]: n }));
  };

  useEffect(() => {
    const listener = layerManager.subscribe(onUpdate);
    return (): void => listener.unsubscribe();
  }, [layerManager, onUpdate]);

  const isAlert = React.useMemo(() => {
    return layers[layers.length - 1]?.type === ALERT;
  }, [layers]);

  const [layerHistory, setLayerHistory] = useState([0]);
  const [timeoutCompleted, setTimeoutCompleted] = useState(false);
  const scrollWindow = () => {
    if (window?.scrollTo) {
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      disableBodyContent(false);
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  };
  const disableBodyContent = (disable: boolean) => {
    const bodyElements = document.querySelectorAll('body *:not(.layer-content *)');
    const focusableElementsString =
      'a[href]:not([disabled]), button:not([disabled]), input:not([disabled])';
    // Select all body elements not inside the modal
    bodyElements.forEach((element) => {
      if (element.matches(focusableElementsString)) {
        element.setAttribute('tabindex', disable ? '-1' : '0'); // Prevent focusing
        element.setAttribute('aria-hidden', disable ? 'true' : 'false'); // Optionally set aria-hidden
      }
    });
  };
  useEffect(() => {
    // Remove position fixed when layer is closed before timeout is completed
    if (layers.length === 0 && timeoutCompleted) {
      scrollWindow();
      setTimeoutCompleted(false);
    }

    if (layers.length !== 0 && timeoutCompleted) {
      setTimeoutCompleted(false);
    }
  }, [layers, timeoutCompleted]);

  useEffect(() => {
    if (layers.length === 0) {
      setScrollOffsetState({});
    }

    setLayerHistory((prev) => {
      // Prevent page scrolling when layer is open - especially in Safari for iOS
      // see https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
      /* istanbul ignore next */
      if (document?.body.style) {
        // case: layer(s) close
        if (layers.length === 0 && prev.length > 1) {
          scrollWindow();
        }

        // case: layer open
        if (layers.length === 1 && prev.length === 1) {
          setTimeout(() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            disableBodyContent(true);
            document.body.style.width = '100%';
            setTimeoutCompleted(true);
          }, 500);
        }
      }

      if (layers.length === 0) {
        return [0];
      }

      return [...prev, layers.length];
    });
  }, [layers.length]);

  const [startHideAnimation, setStartHideAnimation] = useState(false);

  if (layers.length === 0) {
    return null;
  }

  return (
    <>
      <Shader
        data-audi-core-tracking-include="true"
        data-module="one-layer"
        data-testid="one-layer-shader"
        featureAppId={featureAppId}
        layers={layers}
        zIndex={isAlert ? zIndexAlert : zIndex}
      />
      {layers.map((layerDescription, index) => {
        const { layer } = layerDescription;
        const isTopLayer = index === layers.length - 1;
        const isSecondLayer = index === layers.length - 2;
        const isThirdLayer = index === layers.length - 3;

        if (isTopLayer || isSecondLayer || isThirdLayer) {
          return (
            <OneLayerManager
              active={isTopLayer}
              index={index}
              key={layer.id}
              layerDescription={layerDescription}
              layerHistory={layerHistory}
              layerLength={layers.length}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              scrollOffset={scrollOffsetState[layer.id] || 0}
              setScrollOffset={setOneLayerScrollOffset}
              setStartHideAnimation={setStartHideAnimation}
              startHideAnimation={startHideAnimation}
              zIndex={zIndex}
              featureAppId={featureAppId}
              zIndexAlert={zIndexAlert}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default OneLayerCollection;
