/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
// WebPack generation of HTML files under `/demo`
// import './demo/index.html';
import React from 'react';
import { ThemeProvider, audiLightTheme } from '@audi/audi-ui-react';
import { HistoryServiceV3 } from '@feature-hub/history-service';
import { FeatureAppDefinition, FeatureServices, Logger } from '@feature-hub/core';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { ReactFeatureApp } from '@feature-hub/react';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { SpawnContext } from '@oneaudi/falcon-tools';
import OneLayerCollection from './components/one-layer-collection';
import { LayerContentHTML } from './utils';
import { AppContextProvider } from './context/app';
import { clearOneLayerQuery } from './components/util';

export const FEATURE_APP_NAME = 'oneaudi-feature-app-one-layer';

export interface Dependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'layer-manager': LayerManagerV27;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 's2:history': HistoryServiceV3;
  readonly 'audi-content-service': ContentServiceV1;
}

interface Config {
  zIndexAlert: number;
  zIndex?: number;
  fefaFeatureAppUrl?: string;
  oneFootnoteUrl?: string;
}

type StaticFalconConfig = {
  __type?: 'aem-headless';
  fields: {
    zIndexAlert?: number;
    zIndex?: number;
    fefaFeatureAppUrl?: string;
    oneFootnoteJsonUrl: string;
  };
};

type FeatureAppDefinitionType = FeatureAppDefinition<ReactFeatureApp, Dependencies, Config>;

function normalizeConfig(config?: Config | StaticFalconConfig): Config | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config.__type === 'aem-headless') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return config?.config?.fields as Config;
  }
  return config as Config;
}

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices: services, config: rawConfig, featureAppId }) => {
    const config = normalizeConfig(rawConfig);
    const zIndexAlert = config?.zIndexAlert || 100;
    const zIndex = config?.zIndex;
    const fefaFeatureAppUrl = config?.fefaFeatureAppUrl;
    const oneFootnoteUrl = config?.oneFootnoteUrl;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isOneCMS = rawConfig?.__type === 'aem-headless';
    // const fefaFeatureAppUrl = config?.fefaFeatureAppUrl;
    // const oneFootnoteJsonUrl = config?.oneFootnoteJsonUrl;

    const layerManager = services['layer-manager'];
    const historyService = services['s2:history'];
    const serviceConfigProvider = services[
      'gfa:service-config-provider'
    ] as GfaServiceConfigProviderV1;
    const contentApiConfig = isOneCMS ? serviceConfigProvider?.audi['falcon-content-api'] : null;
    if (typeof window !== 'undefined') {
      const handleHashChange = () => {
        const urlHash = window.location.hash;
        const currentUrl = new URL(window.location.href);
        const { protocol } = currentUrl;
        const hasDeepLink = urlHash.includes('one-layer') || urlHash.includes('oneLayer');
        const url = urlHash.replace('#oneLayer=', '').replace('#one-layer=', '');
        const isProtocol = url.startsWith('audi') ? `${protocol}/` : '';
        const htmlUrl = `${isProtocol}/${url}`;
        const cleanedUrl = htmlUrl.replace(/^\/\//, '/');
        const urlParams = window.location.search;
        const fefaParam = urlParams?.includes('withFefa');
        const LayerContentHTMLComponent = fefaParam ? (
          <LayerContentHTML enableDeepLinking url={cleanedUrl} withFefa />
        ) : (
          <LayerContentHTML enableDeepLinking url={cleanedUrl} />
        );

        if (hasDeepLink && cleanedUrl && layerManager) {
          // Protocol and Host need to be filled in because url is is written without them
          // like href='/de/brand/de/tools/navigation/layer/impressum.html'

          layerManager.openFocusLayer(
            () => LayerContentHTMLComponent,
            {},
            {
              size: FocusLayerSizeV2.A,
              userCloseable: true,
              // Go back to a URL without a one-layer query
              onClose: () => {
                const layers = layerManager.getLayers();
                clearOneLayerQuery(layers.length, historyService);
              }
            }
          );
        }
      };
      window.addEventListener('hashchange', handleHashChange);
      handleHashChange();
    }

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App,
      // that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <ThemeProvider theme={audiLightTheme}>
          <AppContextProvider
            historyService={historyService}
            // @ts-expect-error content api cofing may be null
            contentApiConfig={contentApiConfig}
            isHeadless={isOneCMS}
            fefaFeatureAppUrl={fefaFeatureAppUrl}
            oneFootnoteUrl={oneFootnoteUrl}
          >
            <SpawnContext>
              <OneLayerCollection
                {...{ featureAppId, zIndex, zIndexAlert, historyService }}
                layerManager={layerManager}
              />
            </SpawnContext>
          </AppContextProvider>
        </ThemeProvider>
      )
    };
  },

  dependencies: {
    featureServices: {
      'layer-manager': '^2',
      's2:logger': '^1.0.0',
      's2:history': '^3.0.0',
      'gfa:service-config-provider': '^1.0.0',
      'audi-content-service': '^1.0.0'
    },
    externals: {
      react: '^18.2.0',
      '@audi/audi-ui-react-v2': '^3',
      '@feature-hub/react': '^3',
      'styled-components': '^5.1.1'
    }
  },

  optionalDependencies: {
    featureServices: {}
  }
};

export default featureAppDefinition;
